<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-card>
        <v-card-title primary-title>{{ $t("new") }} </v-card-title>
        <v-card-text>
          <div class="subtitle-2">
            {{ $t("template") }}
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-help-box</v-icon>
                </v-btn>
              </template>
              <span> {{ $t("templateIntro") }}</span>
            </v-tooltip>
          </div>
          <v-row class="justify-center align-center" dense>
            <v-col md="10">
              <v-select
                :items="templates"
                outlined
                v-model="template"
                :label="$t('template')"
                hide-details
                dense
              ></v-select>
            </v-col>
            <v-col md="2">
              <v-btn color="secondary" @click="setTemplate">
                {{ $t("confirm") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <div class="subtitle-2">{{ $t("customize") }}</div>
          <v-row class="justify-center align-center" dense>
            <v-col cols="6" v-if="$root.$i18n.locale === 'zh'">
              <v-text-field
                v-model="customCountry.name_zh"
                label="中文名"
                outlined
                hide-details
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="customCountry.name_en"
                :label="$t('name-en')"
                outlined
                hide-details
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="1">
              <v-btn color="secondary" @click="addCustomToSession">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <p class="text-button">{{ $t("select-country") }}</p>
          <v-text-field
            flat
            outlined
            v-model="search"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
          <v-container style="max-height: 500px" class="overflow-y-auto">
            <v-list>
              <v-list-item
                v-for="item in outSessionCountriesFiltered"
                :key="item.code"
              >
                <v-list-item-avatar>
                  <v-img :src="getImage(item.code)"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <span v-if="$root.$i18n.locale === 'zh'"
                      >{{ item.name_zh }}
                    </span>
                    {{ item.name_en }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="addToSession(item)">
                    <v-icon color="grey lighten-1">mdi-plus</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card>
        <v-card-title primary-title>
          <v-text-field
            v-model="committeeName"
            :label="$t('committee-name')"
            outlined
          ></v-text-field>
          <v-spacer></v-spacer>
          {{ inSessionCountries.length }} {{ $t("countries") }}
        </v-card-title>
        <v-card-text>
          <v-container style="max-height: 500px" class="overflow-y-auto">
            <div v-if="this.$store.getters.inSessionCountriesEmpty">
              <p class="text-h6">{{ $t("add-countries") }}</p>
            </div>
            <v-list-item v-for="item in inSessionCountries" :key="item.code">
              <v-list-item-avatar>
                <v-img :src="getImage(item.code)"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="$root.$i18n.locale === 'zh'"
                    >{{ item.name_zh }}
                  </span>
                  {{ item.name_en }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="deleteSession(item)">
                  <v-icon color="grey lighten-1">mdi-minus</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn
                color="error"
                block
                text
                @click="deleteAllInSession"
                class="mb-1"
              >
                {{ $t("cancel-all") }}
              </v-btn>
              <v-btn color="secondary" block to="/rollCall">
                {{ $t("start") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  metaInfo: {
    title: "设置国家",
  },
  data() {
    return {
      langs: ["en", "zh"],
      customCountry: {
        code: "UN",
        name_zh: "",
        name_en: "",
        status: "A",
        on_GSL: false,
        vote_status: "",
        power_status: "Delegate",
        on_mod: false,
      },
      templates: [
        "ASEAN",
        "ECOSOC",
        "EU",
        "G20",
        "NATO",
        "UNHRC",
        "UNICEF",
        "SC",
      ],
      template: "",
      search: "",
      outSessionCountriesFiltered: [],
    };
  },
  watch: {
    search(val) {
      this.filterOutSessionCountries(val);
    },
    outSessionCountries(val) {
      this.outSessionCountriesFiltered = val;
    },
    inSessionCountriesEmpty: {
      immediate: true,
      handler(inSessionCountriesEmpty) {
        if (inSessionCountriesEmpty) {
          this.$store.commit("initializeOutSessionCountries");
        }
      },
    },
  },
  computed: {
    committeeName: {
      get() {
        return this.$store.state.currentSession.committee;
      },
      set(v) {
        this.$store.commit("setCommittee", v);
      },
    },
    inSessionCountries() {
      return this.$store.getters.sortedInSessionCountries;
    },
    outSessionCountries() {
      return this.$store.getters.sortedOutSessionCountries;
    },
    inSessionCountriesEmpty() {
      return this.$store.getters.inSessionCountriesEmpty;
    },
  },
  methods: {
    filterOutSessionCountries(val) {
      console.log("filtering");
      const text = val.toLowerCase();
      const arr = this.outSessionCountries.filter(
        (x) =>
          x.code.toLowerCase().includes(text) ||
          x.name_zh.toLowerCase().includes(text) ||
          x.name_en.toLowerCase().includes(text)
      );
      this.outSessionCountriesFiltered = arr;
    },
    getImage(code) {
      if (code.length > 3) {
        return require(`../assets/flags/UN.svg`);
      } else {
        return require(`../assets/flags/${code}.svg`);
      }
    },
    setTemplate() {
      this.deleteAllInSession();
      var codes = [];
      if (this.template === "G20") {
        codes = [
          "ARG",
          "AUS",
          "BRA",
          "CAN",
          "CHN",
          "EU",
          "FRA",
          "DEU",
          "IND",
          "IDN",
          "ITA",
          "JPN",
          "MEX",
          "KOR",
          "RUS",
          "SAU",
          "ZAF",
          "TUR",
          "GBR",
          "USA",
        ];
        this.$store.commit("setCommittee", "G20");
        this.committeeName = "G20";
      }
      if (this.template === "ASEAN") {
        codes = [
          "BRN",
          "KHM",
          "IDN",
          "LAO",
          "MYS",
          "MMR",
          "PNG",
          "PHL",
          "SGP",
          "THA",
          "VNM",
        ];
        this.$store.commit(
          "setCommittee",
          "Association of Southeast Asian Nations"
        );
        this.committeeName = "Association of Southeast Asian Nations";
      }
      if (this.template === "ECOSOC") {
        codes = [
          "AGO",
          "ARM",
          "AUS",
          "BGD",
          "BLR",
          "BEN",
          "BRA",
          "BWA",
          "CAN",
          "CHN",
          "COL",
          "COG",
          "ECU",
          "EGY",
          "SLV",
          "ETH",
          "FIN",
          "FRA",
          "GAB",
          "DEU",
          "GHA",
          "IND",
          "IRN",
          "IRL",
          "JAM",
          "JPN",
          "KEN",
          "LVA",
          "LUX",
          "MWI",
          "MLI",
          "MLT",
          "MEX",
          "MNE",
          "MAR",
          "NLD",
          "NIC",
          "NOR",
          "PSE",
          "PAN",
          "PRY",
          "PHL",
          "KOR",
          "RUS",
          "SAU",
          "ESP",
          "SDN",
          "CHE",
          "THA",
          "TGO",
          "TKM",
          "UKR",
          "USA",
          "URY",
        ];
        this.$store.commit("setCommittee", "Economic and Social Council");
        this.committeeName = "Economic and Social Council";
      }
      if (this.template === "NATO") {
        codes = [
          "ALB",
          "BEL",
          "BGR",
          "CAN",
          "HRV",
          "CZE",
          "DNK",
          "EST",
          "FRA",
          "DEU",
          "GRC",
          "HUN",
          "ISL",
          "LVA",
          "LTU",
          "LUX",
          "MNE",
          "NLD",
          "MKD",
          "NOR",
          "POL",
          "PRT",
          "ROU",
          "SVN",
          "SVK",
          "ESP",
          "TUR",
          "GBR",
          "USA",
        ];
        this.$store.commit(
          "setCommittee",
          "North Atlantic Treaty Organization"
        );
        this.committeeName = "North Atlantic Treaty Organization";
      }
      if (this.template === "EU") {
        codes = [
          "AUT",
          "BEL",
          "BGR",
          "HRV",
          "CYP",
          "CZE",
          "DNK",
          "EST",
          "FIN",
          "FRA",
          "DEU",
          "GRC",
          "HUN",
          "IRL",
          "ITA",
          "LVA",
          "LTU",
          "LUX",
          "MLT",
          "NLD",
          "POL",
          "PRT",
          "ROU",
          "SVK",
          "SVN",
          "ESP",
          "SWE",
        ];
        this.$store.commit("setCommittee", "European Union");
        this.committeeName = "European Union";
      }
      if (this.template === "UNHRC") {
        codes = [
          "AFG",
          "AGO",
          "ARG",
          "AUS",
          "AUT",
          "BHS",
          "BHR",
          "BGD",
          "BRA",
          "BGR",
          "BFA",
          "CMR",
          "CHL",
          "CHN",
          "HRV",
          "CUB",
          "CZE",
          "DNK",
          "COD",
          "EGY",
          "ERI",
          "FJI",
          "HUN",
          "ISL",
          "IRQ",
          "ITA",
          "JPN",
          "MEX",
          "NPL",
          "NGA",
          "PAK",
          "PER",
          "PHL",
          "QAT",
          "RWA",
          "SAU",
          "SEN",
          "SVK",
          "SOM",
          "ZAF",
          "ESP",
          "TGO",
          "TUN",
          "UKR",
          "ARE",
          "GBR",
          "URY",
        ];
        this.$store.commit(
          "setCommittee",
          "United Nations Human Rights Council"
        );
        this.committeeName = "United Nations Human Rights Council";
      }
      if (this.template === "UNICEF") {
        codes = [
          "BGD",
          "BEN",
          "BRA",
          "BDI",
          "CMR",
          "CAN",
          "CHN",
          "COL",
          "CUB",
          "DNK",
          "DEU",
          "DJI",
          "EST",
          "GHA",
          "IRL",
          "JPN",
          "LTU",
          "MEX",
          "MNG",
          "MAR",
          "NZL",
          "NOR",
          "PAK",
          "PRY",
          "KOR",
          "RUS",
          "ESP",
          "SDN",
          "CHE",
          "SWE",
          "TKM",
          "GBR",
          "USA",
          "YEM",
          "ZWE",
        ];
        this.$store.commit("setCommittee", "United Nations Children's Fund");
        this.committeeName = "United Nations Children's Fund";
      }
      if (this.template === "SC") {
        codes = [
          "BEL",
          "CHN",
          "DOM",
          "EST",
          "FRA",
          "DEU",
          "ZAF",
          "IDN",
          "NER",
          "RUS",
          "VCT",
          "TUN",
          "GBR",
          "USA",
          "VNM",
          "AFG",
        ];
        this.$store.commit("setCommittee", "United Nations Security Council");
        this.committeeName = "United Nations Security Council";
      }
      codes.forEach((code) => {
        const country = this.outSessionCountries.find((x) => x.code === code);
        this.$store.commit("addToSession", country);
      });
    },
    addToSession(item) {
      this.$store.commit("addToSession", item);
    },
    addCustomToSession() {
      const code =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      var payload = JSON.parse(JSON.stringify(this.customCountry));
      payload.code = code;
      this.$store.commit("addCustomToSession", payload);
    },
    deleteSession(item) {
      this.$store.dispatch("deleteInSessionCountry", item);
    },
    deleteAllInSession() {
      this.$store.commit("deleteAllInSession");
    },
  },
  created() {
    this.outSessionCountriesFiltered = JSON.parse(
      JSON.stringify(this.$store.getters.sortedOutSessionCountries)
    );
  },
};
</script>

<i18n>
{
  "en": {
    "new": "Setup the Committee",
    "template": "Template Committee",
    "templateIntro": "The template committee can change over time. You can customize countries based on it",
    "customize": "Customize Country",
    "name-en": "Name",
    "select-country": "Select Country",
    "your-committee": "Your Committee",
    "committee-name": "Committee Name",
    "countries": "Countries",
    "save": "Save",
    "mod": "Mod",
    "add-countries": "Please Add Countries",
    "cancel-all": "Clear All",
    "confirm": "Confirm",
    "start": "Start the Conference"
  },
  "zh": {
    "new": "设置委员会",
    "template": "选择预设委员会",
    "templateIntro": "预设委员会可能会随时间变动。可以在此基础上添加删除国家",
    "customize": "自定义国家",
    "name-en": "英文名",
    "select-country": "选择国家",
    "your-committee": "你的委员会",
    "committee-name": "委员会名称",
    "save": "保存",
    "countries": "个国家",
    "add-countries": "请添加委员会国家",
    "cancel-all": "全部取消",
    "confirm": "确认",
    "start": "开始会议"
  }
}
</i18n>
